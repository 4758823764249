(function ($, Drupal) {

    $(function() {

        // Check if the device is iOS
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (iOS) {
            var $formSubmit = $('#simple-cookie-compliance-dismiss-form #edit-submit');

            if ($formSubmit) {
                $formSubmit.on('click', function() {
                    // Changes the click, which doesn't work on the form button, to be a mouseclick instead! (:
                    $(this).trigger('mousedown');
                });
            }
        }

    });

})(jQuery, Drupal);